import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FailReasons } from '../components/BookingServicePage/constants';

export const LOST_BUSINESS_NOTIFIER_NOTIFY_PATH =
  '/_serverless/wixstores-lost-business-notifier/lost-business';

export function sendNoPaymentNotification(
  httpClient: ControllerFlowAPI['httpClient'],
  failReasons: FailReasons[],
) {
  if (failReasons[0] === FailReasons.Premium) {
    httpClient
      .post(LOST_BUSINESS_NOTIFIER_NOTIFY_PATH)
      .catch((e) =>
        console.log(`Failed: ${LOST_BUSINESS_NOTIFIER_NOTIFY_PATH}`),
      );
  }
}
